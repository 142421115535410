import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Navigation from '../components/navigation';
import Section from '../components/section';
import Width from '../components/width';
import Footer from '../components/footer';

const SecondPage = () => (
	<Layout>
		<SEO title="Page two" />
		<Navigation sticky />

		<Section>
			<Width>
				<h2>Hi from the second page</h2>
				<p>
					Welcome to page 2. <Link to="/">Go back to the homepage</Link>
				</p>
			</Width>
		</Section>
		<Section>
			<Width>
				<h2>Hi from the second page</h2>
				<p>
					Welcome to page 2. <Link to="/">Go back to the homepage</Link>
				</p>
			</Width>
		</Section>
		<Section>
			<Width>
				<h2>Hi from the second page</h2>
				<p>
					Welcome to page 2. <Link to="/">Go back to the homepage</Link>
				</p>
			</Width>
		</Section>

		<Footer />
	</Layout>
);

export default SecondPage;
